import React, { Component } from 'react'; 

import DataProtection from './DataProtection';

class FormRefer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name:'',
            email: '',
            profile: '',
            message: '',
            dataProtectionConsent: false,
            showFormRefer: false,
        };
    }

    handleRefer = async (e) => {
        e.preventDefault();
    
        try {
            const { name, email, profile, message, dataProtectionConsent } = this.state;
    
            if (!email) {
                this.setState({ message: 'Email is required' });
                return;
            }
    
            if (!dataProtectionConsent) {
                this.setState({
                    message: 'Please consent to data protection',
                });
                return;
            }
    
            const response = await fetch('/api/referral', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, profile, message, dataProtectionConsent }),
            });
    
            const data = await response.json();
            console.log(data);
    
            if (response.ok) {
                this.setState({
                    message: 'Thank you for the referral.',
                    showFormRefer: false,
                });
            } else {
                const errorMessage = data.error || 'Failed to submit referral.';
                this.setState({ message: errorMessage });
            }
        } catch (error) {
            console.error(error);
            this.setState({ message: 'Failed to submit referral.' });
        }
    };


    render() {
        return (
            <div>
                <form id='refer-form' onSubmit={this.handleRefer}>
    
                    <label htmlFor='name'>
                        Your full name
                    </label>
                        <input 
                            type='text'
                            id='name' 
                            name='name'
                            autoComplete='name'
                            onChange={(e) => this.setState({ name: e.target.value })}
                            required
                            />
    
                    <label htmlFor='email'>
                        Your contact email address
                    </label>
                        <input 
                            type='email' 
                            id='email'
                            name='email' 
                            autoComplete='email'
                            onChange={(e) => this.setState({ email: e.target.value })}
                            required
                            />

                    <label htmlFor='profile'>
                        Link to profile of your referral
                    </label>
                        <input 
                            type='text' 
                            id='profile' 
                            name='profile' 
                            onChange={(e) => this.setState({ profile: e.target.value })}
                            />

                    <label htmlFor='message'>
                        Your message
                    </label>
                        <textarea 
                            id='message' 
                            name='message' 
                            rows='4' 
                            onChange={(e) => this.setState({ message: e.target.value })}
                        ></textarea>

                    <div className='data-policy'>
                        <input 
                            type='checkbox' 
                            id='data-policy' 
                            name='data-policy' 
                            required
                            onChange={(e) => this.setState({ dataProtectionConsent: e.target.checked })}/>
                            <DataProtection />
                    </div>

                    <button type='submit'>REFFER</button>
                </form>
                <p>{this.state.message}</p>
            </div>
);
}
}



export default FormRefer;