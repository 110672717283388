import React, { Component } from 'react'; 

import DataProtection from './DataProtection';

class DownloadServices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: '',
            dataProtectionConsent: false,
            showDownloadServices: false,
        };
    }

    handleDownloadPopupClose = () => {
        this.setState({ showDownloadServices: false });
    };

    handleDownloadClick = () => {
        this.setState({ showDownloadServices: true });
    };

    handleDownload = () => {
        const pdfFileName = 'services.pdf';

        const link = document.createElement('a');
        link.href = `/pdfs/${pdfFileName}`; 
        link.target = '_blank';
        link.download = pdfFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!this.state.email) {
                this.setState({ message: 'Email is required' });
                return;
            }

            if (!this.state.dataProtectionConsent) {
                this.setState({
                    message: 'Please consent to data protection',
                });
                return;
            }

            const response = await fetch('/api/collect-client-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: this.state.email }),
            });

            const data = await response.json();
            console.log(data);

            if (response.ok) {
                this.handleDownload();
                this.setState({
                    message: 'PDF available to be downloaded',
                    showDownloadServices: false,
                });
            } else {
                const errorMessage = data.error || 'Failed to download the PDF';
                this.setState({ message: errorMessage });
            }
        } catch (error) {
            console.error(error);
            this.setState({ message: 'Failed to download PDF' });
        }
    };

    render() {
        return (

           
           <div className='popup'>
                                                            <div className='popup-content'>
                                                                <span 
                                                                        className='close' 
                                                                        onClick={this.props.onClose}>
                                                                        &times;
                                                                </span>
                                                                    <h3>Please Enter Your Email</h3>
                                                                        <form onSubmit={this.handleSubmit}>
                                                                            <input
                                                                                type='email'
                                                                                placeholder='Enter your email'
                                                                                autoComplete='email'
                                                                                value={this.state.email}
                                                                                onChange={(e) => this.setState({ email: e.target.value })}
                                                                                />
            
                                                                                <div className='data-policy'>
                                                                                    <input 
                                                                                        type='checkbox'
                                                                                        id='data-policy' 
                                                                                        name='data-policy'
                                                                                        checked={this.state.dataProtectionConsent}
                                                                                        onChange={() =>
                                                                                            this.setState({
                                                                                                dataProtectionConsent: !this.state.dataProtectionConsent,
                                                                                            })
                                                                                        }
                                                                                        required />
                                                                                <DataProtection />
                                                                                </div>
                                                                            <button type="submit">GET SERVICES</button>
                                                                        </form>
                                                                    <p>{this.state.message}</p>
                                                            </div>
                                                    
                                           
            </div>
        );
    }
};




export default DownloadServices;