import React, { Component } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink} from 'react-router-dom';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrolled = window.scrollY > 0;
    this.setState({ scrolled });
  };

  render() {
    const { scrolled } = this.state;

    // Define the CSS class based on the scrolled state
    const navbarClass = scrolled ? 'navbar-container scrolled' : 'navbar-container';

    return (
      <nav id='home'>
        <section id='navbar'>
            <div className={`${navbarClass} navbar-container`}>
                        <div className='logo'>
                        <RouterLink 
                          to='/'
                          className='RouterLink'>
                                <img 
                                src='/images/logo-gold.png'
                                alt='recberry logo gold circle with letter R in the middle and text recberry agency hackers at the right side next to the golden circle'/>
                            </RouterLink>
                        </div>
                    <div className='menu'>
                        <span>
                            <ScrollLink to='team' className='scroll-link'>TEAM</ScrollLink>
                        </span>
                        <span>
                            <RouterLink to='/KnowHow' className='router-link'>KNOW-HOW</RouterLink>
                        </span>
                        <span>
                            <ScrollLink to='contact' className='scroll-link'>CONTACT</ScrollLink>
                        </span>
                        <span>
                             <RouterLink to='/Jobs' className='router-link'>
                         <span style={{ color: '#D8B725'}}>JOBS</span>
                        </RouterLink>
                      </span>
                    </div>
            </div>
        </section>
      </nav>
    );
  }
}

export default Navbar;