import React, { Component } from 'react'; 
import { Link as ScrollLink } from 'react-scroll';

import Footer from './Footer';
import Landing from './Landing';
import Navbar from './Navbar';

class Home extends Component {
    render() {
        return (
            <div>

                <Navbar />

                {/*/ HERO --> */} 
                        <section id='hero'>
                            <div className='hero'>
                                <div className='hero-title'>
                                    <h1>kickstart your IT <br/>
                                        hiring journey with <br/>
                                        us!
                                    </h1>
                                    <ScrollLink 
                                        to='proposition' 
                                        smooth={true} 
                                        duration={500}>
                                        <button 
                                            type='button' 
                                            name='button'>
                                            LEARN MORE
                                        </button>
                                    </ScrollLink>
                                </div>
                            </div>
                        </section>
                    
                        <Landing />
                        <Footer />
            </div>
        );
    }
}



export default Home;