import './App.css'; 
import './Mobile.css';
import './Pads.css';
import './Big.css';
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import Home from './Home';
import Jobs from './Jobs';
import KnowHow from './KnowHow';
import JobsIn from './JobsIn';
import Data from './Data';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/KnowHow" element={<KnowHow />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="/JobsIn" element={<JobsIn />} />
          <Route path="/Data" element={<Data />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default connect(null, actions)(App);