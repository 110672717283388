import React, { Component } from 'react'; 

import DataProtection from './DataProtection';
import Footer from './Footer';
import NavbarJobs from './NavbarJobs';

class Jobs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            showPopup: false,
            message: '',
            dataProtectionConsent: false,
        };
    }

    handlePopupClose = () => {
        this.setState({ showPopup: false });
    };

    handleWaiting = async (e) => {
        e.preventDefault();
      
        try {
          if (!this.state.email) {
            this.setState({ message: 'Email is required' });
            return;
          }
      
          if (!this.state.dataProtectionConsent) {
            this.setState({
              message: 'Please consent to data protection',
            });
            return;
          }
      
          const response = await fetch('/api/waiting-list', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: this.state.email }),
          });

          const data = await response.json();
      
          if (response.ok) {
            this.setState({
              message: 'Thank you for subscribing to our waiting list!',
              showPopup: true,
            });
          } else {
            const errorMessage = data.error || 'Failed to subscribe to our waiting list';
                this.setState({ message: errorMessage });
          }
        } catch (error) {
          console.error(error);
          this.setState({ message: 'Failed to subscribe to our waiting list' });
        }
      };




render() {
    return (
        <div>
            <NavbarJobs />

            {/*/ HERO */} 
                <section id='jobs'>
                    <div className='hero'>
                        <div className='hero-title'>
                            <h1>welcome to <br/>
                                our job-portal</h1>
                            <h2 className='headline'>are you a talented <span className='orange'>IT</span><br/> 
                                <span className='orange'>Engineer</span> looking for exciting <br/>
                                    career opportunities? You’ve <br/>
                                    come to the right place. </h2>
                                        {/* <button type="button" name="button">
                                        <a href="#proposition" role="button">SIGN UP</a>
                                        </button> */}
                        </div>
                    </div>
                </section>

                <main id='main'>

                    {/*/ ANNOUNCEMENT */} 
                        <h3>Dear <span className='orange'>visitor!</span><br/>
                            Thank you for your interest in our career page</h3>

                        <div className='waiting-list'>
                    <div className='pdf-subscribe'>
                        <h3 className='subscribe-h3'><span className="orange">More Coming Soon:</span><br/></h3>
                            <p className='styled'>we are currently working hard to improve and update our site. during this time, the page is under construction.<br/>
                                if you would like to be informed when it's ready, please provide your email address below, and we'll notify you once it's up and running. 
                                your patience and understanding are highly appreciated.</p>
                            <form onSubmit={this.handleWaiting}>
                                <label 
                                    htmlFor='email'>
                                        Your contact email address
                                </label>
                                    <input 
                                        type='email' 
                                        id='email' 
                                        name='email'
                                        className='waiting'
                                        autoComplete='email'
                                        required
                                        value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value })} />
                                <div className='data-policy-subs'>
                                    <input 
                                        type='checkbox'
                                        id='data-policy'
                                        name='data-policy'
                                        required
                                        checked={this.state.dataProtectionConsent}
                                                        onChange={() =>
                                                            this.setState({
                                                                dataProtectionConsent: !this.state
                                                                    .dataProtectionConsent,
                                                                    })
                                                                } />
                                <DataProtection />
                                </div>
                                <button 
                                    type='submit'>WAITING LIST
                                </button>
                                <p>{this.state.message}</p>
                            </form>
                    </div>
                </div>
                </main>
            
            <Footer />
        </div>
        );
    }
}



export default Jobs;