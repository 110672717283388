import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const DataProtectionLink = () => {
  return (
    <label 
        htmlFor='data-policy'>
            I hereby acknowledge and consent to comply with the{' '}
      <RouterLink to='/Data'>
        <span className='orange'> data protection policy</span>
      </RouterLink>.
    </label>
  );
};

export default DataProtectionLink;