import React, { Component } from 'react'; 

import DataProtection from './DataProtection';

class FormContact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name:'',
            email: '',
            message: '',
            dataProtectionConsent: false,
            showFormContact: false,
        };
    }

    handleContact = async (e) => {
        e.preventDefault();
    
        try {
            const { name, email, message, dataProtectionConsent } = this.state;
    
            if (!email) {
                this.setState({ message: 'Email is required' });
                return;
            }
    
            if (!dataProtectionConsent) {
                this.setState({
                    message: 'Please consent to data protection',
                });
                return;
            }
    
            const response = await fetch('/api/contact-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, message, dataProtectionConsent }),
            });
    
            const data = await response.json();
            console.log(data);
    
            if (response.ok) {
                this.setState({
                    message: 'Thank you for your message. We will get in touch soon.',
                    showFormContact: false,
                });
            } else {
                const errorMessage = data.error || 'Failed to submit your message.';
                this.setState({ message: errorMessage });
            }
        } catch (error) {
            console.error(error);
            this.setState({ message: 'Failed to submit message.' });
        }
    };



    render() {
        return (
            <div>
                <form id='contact-form' onSubmit={this.handleContact}>
    
                    <label htmlFor='name'>
                        Your full name
                    </label>
                        <input 
                            type='text'
                            id='name' 
                            name='name'
                            autoComplete='name'
                            required
                            onChange={(e) => this.setState({ name: e.target.value })}
                            />
    
                    <label htmlFor='email'>
                        Your contact email address
                    </label>
                        <input 
                            type='email' 
                            id='email'
                            name='email' 
                            autoComplete='email'
                            required
                            onChange={(e) => this.setState({ email: e.target.value })}
                            />

                    <label htmlFor='message'>
                        Your message
                    </label>
                        <textarea 
                            id='message' 
                            name='message' 
                            rows='4' 
                            onChange={(e) => this.setState({ message: e.target.value })}
                        ></textarea>

                    <div className='data-policy'>
                        <input 
                            type='checkbox' 
                            id='data-policy' 
                            name='data-policy' 
                            required
                            onChange={(e) => this.setState({ dataProtectionConsent: e.target.checked })}/>
                            <DataProtection />
                    </div>
                    <button type='submit'>CONTACT</button>
                </form>
                <p>{this.state.message}</p>
            </div>
);
}
}



export default FormContact;