import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer id='footer'>
        <div className='footer-content'>
          <div className='social-profiles'>
            <a
              href='https://cz.linkedin.com/company/recberry'
              target='_blank'
              rel='noopener noreferrer'
              className='social-link'
            >
              LinkedIn
            </a>
            <a
              href='https://cs-cz.facebook.com/recberry/'
              target='_blank'
              rel='noopener noreferrer'
              className='social-link'
            >
              Facebook
            </a>
            <a
              href='https://www.foxcrunch.com/'
              target='_blank'
              rel='noopener noreferrer'
              className='social-link'
            >
              Blog
            </a>
            <a
              href='https://twitter.com/rec_berry'
              target='_blank'
              rel='noopener noreferrer'
              className='social-link'
            >
              Twitter
            </a>
          </div>
          <div className='line line-left'></div>
          <div className='line line-right'></div>
        </div>

        <div className='rights'>
          <p>
            &copy; all rights reserved. designed and developed by{' '}
            <img src='images/atom.png' alt="Logo" height="20px" />{' '}
            <a href='http://abaddion.com'
              className='link'>
              abaddion
            </a>
          </p>
        </div>

        <div className='business'>
          <p>
            Recberry s.r.o.| IC: 04972236 | DIC: CZ04972236 | Tupolevova 741,
            Prague 9 Letnany | Incorporated in the Commercial Register kept by
            the City: Court in Prague, Section C, under file No.256441
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;