import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as actions from '../actions';

const JobsIn = ({ auth, logout }) => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    logout();
    navigate('/'); // Navigate to the home page after logout
  };

  // If the user is not authenticated, redirect them to the home page
  if (!auth) {
    navigate('/'); // Navigate to the home page if not authenticated
    return null; // You can return null or a loading indicator here
  }

  return (
    <div>
      <h1>Welcome to JobsIn</h1>
      <button onClick={handleLogoutClick}>Log Out</button>
    </div>
  );
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, actions)(JobsIn);

