import React, { Component } from 'react'; 
import { Link as ScrollLink } from 'react-scroll';

import DownloadServices from './DownloadServices';
import FormRefer from './FormRefer';
import FormContact from './FormContact';

class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            message: '',
            dataProtectionConsent: false,
            showDownloadServices: false,
        };
    }

    handleDownloadPopupClose = () => {
        this.setState({ showDownloadServices: false });
    };


    handleDownloadClick = () => {
        this.setState({ showDownloadServices: true });
    };


    render() {
        return (
            <div>
                    <main id='main'>

                    {/* INTRO-RECBEERY */}
                        <section id='recberry-intro'>
                            <div className='container'>
                                <div className='item-form'>
                                    <h5>Connecting Professionals, Portfolio Sharing and Rewarding Referrals</h5>
                                    <p>we take pleasure in engaging with professionals, showcasing portfolio, and offering rewards for successful referrals. 
                                    if you recommend <span></span> who is successfully hired by one of our clients, we ensure your efforts are recognised and rewarded. </p>       
                                    <FormRefer />
                                </div>

                                <div className='items'>
                                    <div className='item-card'>
                                        <h4>personal agency hackers?</h4>
                                        <h6>Empowering the IT Community: Revolutionising Talent Acquisition for Success</h6>
                                        <p className='p-white'>by leveraging our extensive headhunting experience, we have reshaped traditional personal agency practices. through
                                                                a client-centric approach and innovative strategies, we deliver exceptional results. experience a new era of IT hiring, 
                                                                where our agency sets new standards for value and success.</p>
                                    </div>
                                    
                                    <div className='item-card'>
                                        <h4>headhunting | data mining | networking</h4>
                                        <h6>Transforming the IT Hiring Landscape: Reinventing Personal Agency Practices for Unparalleled Client Value</h6>
                                        <p className='p-white'>leveraging extensive networks, market insights, and online platforms, we actively search for data and connect with talented 
                                                                individuals. but our approach extends beyond sourcing; we cultivate long-term relationships and continuously expand our quality 
                                                                candidate pool. committed to the IT community, our vision drives us to bring meaningful value and innovation.</p>
                                    </div>
                                    
                                    <div className='item-card'>
                                        <h4>additional value offering</h4>
                                        <h6>The Personalised Approach: Empowering Clients, Candidates, and the IT Community</h6>
                                        <p className='p-white'>at our agency, we prioritise the unique needs of both clients and candidates, recognising that individual attention is key. by 
                                                                investing extra time to understand client challenges and develop robust recruitment strategies, we foster successful matches. 
                                                                we also value getting to know the people behind the brand, ensuring alignment with candidate aspirations and supporting the 
                                                                growth of the IT community.</p>
                                    </div>
                            </div>
                            </div>
                        </section>

                        <section id='more-about'>

                            <div className='story'>
                            
                                <div className='story-item'>
                                    <h3><span className='orange'>Redefining</span> Recruitment with an <span className='orange'>innovative</span> and new Agency <span class='orange'>Approach</span></h3>
                                        <p>recberry emerged from a rich portfolio of esteemed clients in the czech market and insights gained from working with multiple personal agencies. we 
                                            recognised the need for change and identified the shortcomings in traditional agency practices driven solely by profit. </p>
                                        <p>our mission is to provide genuine value to both clients and candidates by redefining the role of a recruiter. we are committed to building long-lasting, 
                                            successful teams and reshaping the recruitment landscape.</p>
                                        <p>recberry brings a fresh perspective and we are driven by our extensive IT recruitment know-how and a vision to revolutionise how agencies operate. by
                                            prioritising innovation and delivering a superior recruitment experience, we aim to set new standards in the industry. </p>
                                            <button 
                                                    type='button' 
                                                    name='button'
                                                    onClick={() => window.open('https://calendly.com/_abaddion/30min', '_blank', 'noopener noreferrer')}>
                                                    BOOK A MEETING
                                            </button>

                                </div>

                                <div className='story-item'>
                                    <img 
                                        src='images/ai.png' 
                                        alt='ai face blue background net conections data scientific style' 
                                        className='story-img'
                                        />
                                </div>
                            </div>
                        </section>

                    {/* SERVICES */} 
                    <section id='services'>
                            <div className='story'>
                                <div className='story-item'>
                                        <img 
                                            src='images/services02.png' 
                                            alt='a male and a female having a meeting using laptop in the office'
                                            className='male-img'/>
                                </div>
                                <div className='story-item'>
                                        <h3>Offered <span className='orange'>services</span>: <span className='orange'>Elevate</span> Your IT Talent Acquisition</h3>
                                        <p>In today's tech world, top talent is your advantage. We make it simple for busy CEOs. Here's what we do:</p>
                                    
                                        <ul><span className='orange'>key benefits</span>
                                            <li><strong>Better Talent</strong>: we find top IT talent for you.</li>
                                            <li><strong>Boosted Results</strong>: get more done, faster, and profitably.</li>
                                            <li><strong>No Hiring Mistakes</strong>: we save you time and money with thorough screening.</li>
                                            <li><strong>Stronger Brand</strong>: attract top talent and help them grow.</li>
                                            <li><strong>Flexible Hiring</strong>: adapt to the changing workforce effortlessly.</li>
                                        </ul>

                                        <ul><span className='orange'>our services</span>
                                            <li><strong>Effective Recruitment</strong>: we find the best.</li>
                                            <li><strong>Smooth Processes</strong>: making hiring easy.</li>
                                            <li><strong>Tailored Services</strong>: customized to your needs.</li>
                                        </ul>

                                        <ul><span className='orange'>why choose us</span>
                                            <li><strong>Efficiency</strong>: fast results, no fuss.</li>
                                            <li><strong>Complete Solutions</strong>: from start to finish.</li>
                                            <li><strong>Proven Success</strong>: trusted by tech CTOs and founders.</li>
                                        </ul>
                                        <p>Download our comprehensive services PDF to discover more. We're eager to connect with you soon!</p>

                                        <div>
                                                <button onClick={this.handleDownloadClick}>OUR SERVICES</button>
                                                {this.state.showDownloadServices && (
           
                                                        <DownloadServices
                                                            onClose={this.handleDownloadPopupClose}
                                                        />
                                                    )}
                                            </div>
                                                  
                                          
                                </div>
                            </div>
                    </section>


 
                    {/* THE TEAM */} 
                                <div className='team-img'>
                                    <img 
                                        src='images/team-org.png' 
                                        alt='six members of a professional team in suits blue background office lighting' /> 
                                </div> 
                                
                                <section id='team'> 
                                    <div className='radiant-bckg'></div>
                                    
                                        <div class='the-team'>
                                            <div class='member-card'>
                                                <div className='member-card-top'>
                                                            <img 
                                                                src='images/bb.jpg' 
                                                                width='160px'
                                                                height='210px' 
                                                                className='member-img'
                                                                alt='a female face of barbora jensik, before balazikova, if not visible, check her linkedin profile https://www.linkedin.com/in/barushbalazikova/'/>
                                                            <h4>Barbora JENSIK</h4>
                                                            <p>founder & director</p>
                                                            <div className='social'>
                                                                    <a 
                                                                        href='https://www.linkedin.com/in/barushbalazikova/'
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='icon'>
                                                                        <i className='fab fa-linkedin-in'></i>
                                                                    </a>
                                                                    <a 
                                                                        href='https://www.foxcrunch.com/' 
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='icon'>
                                                                        <i className='fas fa-blog'></i>
                                                                    </a>
                                                                    <a 
                                                                        href='https://www.instagram.com/abaddion/' 
                                                                        target='_blank'
                                                                        rel='noopener noreferrer'
                                                                        className='icon'>
                                                                        <i className='fab fa-instagram'></i>
                                                                        </a>
                                                                        <a 
                                                                            href='https://www.behance.net/abaddion' 
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            className='icon'>
                                                                            <i className='fab fa-behance'></i>
                                                                        </a>
                                                                        <a 
                                                                            href='https://github.com/abaddion'
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            className='icon'>
                                                                            <i className='fab fa-github'></i>
                                                                        </a>
                                                            </div>            
                                                </div>
                                                <div className='member-card-bottom'>
                                                            <h5>Passionate UX/UI Designer, Web Developer, Principal IT Recruiter</h5>
                                                            <p>passionate about fine art and designing captivating UX/UI interfaces, I excel in web development 
                                                                using HTML, CSS, ReactJS, Redux, NodeJS, and ExpressJS. with a strong background in recruitment and employment branding, I expertly match IT developers 
                                                                with their ideal career opportunities. check out my portfolio at <a 
                                                                                                                                    href='http://abaddion.com/'
                                                                                                                                    target='_blank'
                                                                                                                                    rel='noopener noreferrer'>
                                                                                                                                    <span>www.abaddion.com</span>
                                                                                                                                </a>  and GitHub profile for my programming skills.</p>
                                                </div>
                                            </div>
                                    
                                            <div class='member-card'>
                                                <div className='member-card-top'>
                                                            <img 
                                                                src="images/ms.jpg" 
                                                                width="160px" 
                                                                height="210px" 
                                                                className='member-img'
                                                                alt='a female face of maria prazska, before simova. if not visible check her linkedin profile https://www.linkedin.com/in/mariaprazska/ '/>
                                                            <h4>Maria PRAZSKA</h4>
                                                            <p>networker & data miner</p>
                                                            <div className='social'>
                                                                <a 
                                                                    href='https://www.linkedin.com/in/mariaprazska/'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-linkedin-in'></i>
                                                                </a>
                                                                <a 
                                                                    href='https://www.facebook.com/maria.simova.56' 
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-facebook-f'></i>
                                                                </a>
                                                                <a 
                                                                    href='https://www.instagram.com/mariprazska/'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-instagram'></i>
                                                                </a>
                                                            </div>
                                                    </div>
                                                    <div className='member-card-bottom'>
                                                            <h5>Innovative Talent Acquisition Specialist & Recruitment Advocate</h5>
                                                            <p>passionate about modernising recruitment, I aim to transform traditional enterprises into thriving online startups. treating candidates as individuals 
                                                            and prioritising quality, I connect top talents with exceptional teams in a professional and friendly atmosphere. with a mission to revolutionise czech labor 
                                                            market’s recruitment process, I strive to restore the value of third-party services.</p>
                                                    </div>
                                            </div>
                    
                                            <div class="member-card">   
                                                    <div className='member-card-top'>
                                                            <img 
                                                                src='images/ak.jpg'
                                                                width='160px' 
                                                                height='210px' 
                                                                className='member-img'
                                                                alt='a female face of alexandra kumicikova. if not visible check her linkedin profile at https://www.linkedin.com/in/alexandra-kumič%C3%ADková-8b492383/'/>
                                                            <h4>Alexandra KUMICIKOVA</h4>
                                                            <p>networker & data miner</p>
                                                            <div class='social'>
                                                                <a 
                                                                    href='https://www.linkedin.com/in/alexandra-kumič%C3%ADková-8b492383/'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i class='fab fa-linkedin-in'></i>
                                                                </a>
                                                                <a
                                                                    href='https://www.facebook.com/alexandra.kumicikova'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-facebook-f'></i>
                                                                </a>
                                                                <a 
                                                                    href='https://www.instagram.com/sasenocena/'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-instagram'></i>
                                                                </a>
                                                            </div>
                                                    </div>
                                                    <div className='member-card-bottom'>
                                                            <h5>Talent Acquisition Specialist & HR Coordinator</h5>
                                                            <p>with a proven track record in HR, recruitment, and marketing, I specialise in creating and managing talent pools, primarily for software developers. from raising 
                                                                awareness about our company to conducting initial candidate screenings, resourcing, and interviews, I handle the end-to-end recruitment process. additionally, I excel 
                                                                in event management, organising hackathons and team-building activities.</p>
                                                    </div>
                                                </div>
                    
                                                <div class='member-card'>
                                                    <div className='member-card-top'>
                                                            <img 
                                                                src='images/vs.png'
                                                                width='160px'
                                                                height='210px' 
                                                                className='member-img'
                                                                alt='a female face of viktoria sztezkalova. if not visible check her LinkedIn profile at https://www.linkedin.com/in/alexandra-kumič%C3%ADková-8b492383/'/>
                                                            <h4>Viktoria SZTEZKALOVA</h4>
                                                            <p>networker & data miner</p>
                                                            <div className='social'>
                                                                <a 
                                                                    href='https://www.linkedin.com/in/viktoria-sztezkalova-15350b250/'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-linkedin-in'></i>
                                                                </a>
                                                                <a 
                                                                    href='https://www.facebook.com/viki.sztezkalova'
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    className='icon'>
                                                                    <i className='fab fa-facebook-f'></i>
                                                                </a>
                                                            </div>
                                                    </div>
                                                    <div className='member-card-bottom'>
                                                            <h5>Passionate HR Management Student and IT Recruiter</h5>
                                                            <p>final year HR student and dedicated IT recruiter with a knack for connecting top talent. despite being an introvert, I excel in understanding goals and finding 
                                                                fulfilling career opportunities. committed to the best fit for candidates, I prioritise their happiness over all else. let’s connect for exciting growth opportunities.</p>
                                                    </div>
                                                </div>
                                            </div>
                                </section>


                        {/* TRY US */} 
                            <section id='proposition'>
                                        <div className='story'>
                                            <div className='story-item'>
                                                    <h3>Experience <span className='orange'>the Difference Transparent</span> and Results-Driven Recruitment</h3>
                                                    <p>we understand your frustrations with the numerous recruiters, headhunters, and talent acquisition consultants who often fail to deliver the value they promise. 
                                                        that’s why we stand apart from the crowd and offer a refreshing approach to recruitment.</p>
                                                    <div className='prop'>
                                                    <h5>here’s our proposition*</h5>
                                                    <p>tell us about your challenging IT position that has been difficult to fill, and let us showcase our expertise. we are confident in our ability to find high-quality
                                                    candidates who meet your specific requirements. share the fee you are willing to pay for successfully bringing a top-notch professional on board, and leave the job to 
                                                    us.</p>
                                                    <p>we are committed to delivering tangible results and providing a seamless recruitment experience.no empty promises or marketing gimmicks—just effective solutions 
                                                        tailored to your needs.</p>
                                                    <p>experience the difference in recruitment. <ScrollLink 
                                                                                                        to='contact' 
                                                                                                        smooth={true} 
                                                                                                        duration={500}
                                                                                                        className='link'> get in touch with us
                                                                                                </ScrollLink> or 
                                                                                                <a 
                                                                                                    href="https://calendly.com/_abaddion/30min" 
                                                                                                    target='_blank'
                                                                                                    rel='noopener noreferrer'>
                                                                                                    <span> book a meeting</span> 
                                                                                                </a> today to discuss your hard-to-fill IT position and let us demonstrate our capabilities.
                                                    </p>
                                                    <p className="pls-note">* the proposition outlined above is for hiring managers and companies who have not worked with us before and is limited to one position. for 
                                                        long-term partnerships and additional services, specific fee structures will apply, which will be discussed and agreed upon by both parties. 
                                                                <ScrollLink 
                                                                    to="contact" 
                                                                    smooth={true} 
                                                                    duration={500} 
                                                                    className="link"> contact us 
                                                                    </ScrollLink> to discuss your recruitment needs and explore the possibilities of a long-term partnership.
                                                    </p>
                                                    </div>
                                            </div>

                                            <div className='story-item'>
                                                <div className="black-box">
                                                    <h5>what sets us apart?</h5>
                                                    <p>unlike many other recruitment agencies and freelancers, <span className="orange">we do match skills, but also people and culrure</span>. we prioritize 
                                                        results and transparent collaboration. we won’t waste your time with lengthy sales pitches that fail to deliver real outcomes. instead, we invite you 
                                                        to experience the difference firsthand.</p>
                                                    <div className="try-image">
                                                        <img 
                                                            src="images/bulb.png" 
                                                            alt="a male hand dressed in suit holding a bulb"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </section>  


                        {/* RECOMMENDATION */}
                            <section id='recommendation'>
                                <div className='story'>
                                    <div className='story-item'>
                                        <img 
                                            src='images/male.png' 
                                            alt='a male in suit looking professional with dark blue background and gold details behind him'
                                            className='male-img'/>
                                    </div>
                                    <div className='story-item'>
                                        <h3>Unlocking <span className='orange'>Success</span> Stories Client <span className='orange'>Recommendations</span></h3>
                                            <div className='recommend'>
                                                <div className='content-slider'>
                                                    <div className='slider'>
                                                        <div className='mask'>
                                                            <ul>
                                                                <li className='anim1'>
                                                                    <div className='quote'>
                                                                        <q>I rely on Barbora already for more than 2 years. She is always the person I go to when we need to hire 
                                                                            new engineers into our projects. She has a great sense for recognising the talent and culture fit into 
                                                                            our teams. I've never seen anyone structuring all the information as well as she is.</q>
                                                                    </div>
                                                                    <div className='source'>
                                                                        <p>
                                                                            <a 
                                                                                href='https://www.linkedin.com/in/krejca/'>
                                                                                    Robert Krejca</a><br/>
                                                                                    CEO and Culture Jockey
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className='anim2'>
                                                                    <div className='quote'>
                                                                        <q>I've met dozens of recruiters who claimed to be "determined to help us", but until I met Barbora, I didn't 
                                                                            think they actually presented a real added value. Building employment branding, changing the way we approach 
                                                                            our future employees, all that helped me to understand and appreciate the way Barbora works. We got our first 
                                                                            guy with a snap of a finger, and the whole process was smooth and flawless.</q>
                                                                    </div>
                                                                    <div className='source'>
                                                                        <p>
                                                                            <a 
                                                                                href='https://www.linkedin.com/in/jan-klusacek-b162746/'>
                                                                                    Jan Klusacek</a><br/>
                                                                                    CEO @ Cyber Fox
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                                <li className='anim3'>
                                                                    <div className='quote'>
                                                                        <q>Barbora was helping us on consulting basis and helped us hire 4 great engineers just in 2 months. I'm very happy 
                                                                            with the result of our cooperation and definitely recommend Barbora to anyone who struggles to acquire talent on 
                                                                            today's tough tech job market.</q>
                                                                    </div>
                                                                    <div className='source'>
                                                                        <p>
                                                                            <a 
                                                                                href='https://www.linkedin.com/in/mskvarek/'>
                                                                                    Marian Skvarek</a><br/>
                                                                                    Founder @ Realpad</p>
                                                                    </div>
                                                                </li>
                                                                <li className='anim4'>
                                                                    <div className='quote'>
                                                                        <q>I used to cooperate with Barbora in REED. I do percept our cooperation as very successful one. Barbora is one of 
                                                                            the very few agency consultants who has managed to get any Hires @Skype = must be good! Her friendly approach 
                                                                            combined technical background and passion in technology I can recommend as interesting benefit for anyone who 
                                                                            is looking for skilled IT professionals.</q>
                                                                    </div>
                                                                    <div className='source'>
                                                                        <p>
                                                                            <a 
                                                                                href='https://www.linkedin.com/in/miroslavvasko/'>
                                                                                    Miroslav Vasko</a><br/>
                                                                                    Partner @ nymble ltd, former @ Skype</p></div>
                                                                </li>
                                                                <li className='anim5'>
                                                                    <div className='quote'>
                                                                        <q>Regardless of whether we needed an in-house employee, a contractor, a developer or a tester, Barbora consistently 
                                                                            met the challenge. From tight budget to searching in less conventional European regions, or seeking quite 
                                                                            peculiar skill set or experience, Barbora always found a way.mBeyond her recruiting talents, she was able to 
                                                                            provide invaluable HR consultancy. She understands the essence of organisation dynamics and fits. To anyone 
                                                                            seeking a seasoned recruiter and HR adviser in Europe, I can't recommend Barbora enough. Our collaboration has 
                                                                            been both productive and enjoyable.</q>
                                                                    </div>
                                                                    <div className='source'>
                                                                        <p>
                                                                            <a 
                                                                                href='https://www.linkedin.com/in/tadeasmoravec/'>
                                                                                    Tadeáš Moravec</a><br/>  
                                                                                    Senior Engineering Manager, former @ Akamai</p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </section>

                        {/* PORTFOLIO */} 
                            <section id='portfolio'>
                                <div className='story'>
                                    <div className='story-item'>
                                        <h3><span className='orange'>Trusted</span> Partners Our Impressive <span className='orange'>Clientele</span></h3>

                                        <span className='logo-client'>
                                            <img 
                                                src='images/be.jpg' 
                                                alt='brand embassy'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/angel.jpg' 
                                                alt='angelcam'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/akamai.jpg' 
                                                alt='akamai'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/suse.jpg'
                                                alt='suse linux'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/o.png'
                                                alt='OPT On Demand'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/ec.jpg' 
                                                alt='easycore'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/v.jpg'
                                                alt='vendavo'/>
                                        </span>
                                        <span className='logo-client'>
                                            <img 
                                                src='images/skype.jpg' 
                                                alt='skype'/>
                                        </span>          
                                    </div>
                                    <div className='story-item'>
                                        <img 
                                            src='images/female.png'
                                            alt='female recruiter blue background blond professional in suit with gold aspects in the background'
                                            className='female-img'/>
                                    </div>               
                                </div>
                            </section>
                        

                        {/* CONTACT */}
                            <section id='contact'>
                                <div className='story'>
                                    <div className='story-item'>
                                        <div className='black-box'>
                                            <h3>Our Commitment to <span className='orange'>Exceptional</span> Results</h3>
                                                <p>we are passionate about delivering tailored recruitment solutions that drive success for 
                                                    our clients. with our deep industry knowledge, extensive network, and meticulous screening 
                                                    process, we consistently connect organisations with top-tier talent who not only meet the 
                                                    required qualifications but also align with their unique company culture. </p>
                                                <p>by understanding our clients’ needs inside out, we ensure a seamless recruitment experience and 
                                                    empower companies to build high-performing teams that propel their growth and competitiveness in 
                                                    today’s dynamic market. </p>
                                                <p>trust us to be your strategic recruitment partner, and experience the difference of working with 
                                                    professionals who truly understand your business.</p>
                                        </div>
                                    </div>
                                    <div className='story-item contact-form'>
                                        <h2>Get in touch</h2>
                                        <FormContact />
                                        <div className='phone-info'>
                                            <i className='fa fa-phone'></i>
                                            <span className='mobile'>+420 702 080 993</span>
                                        </div>
                                    </div>
                                </div>
                            </section>

                    </main>
            </div>
);
}
}



export default Landing;