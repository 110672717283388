import React, { Component } from 'react'; 
import { Link as ScrollLink } from 'react-scroll';

import DataProtection from './DataProtection';
import DownloadWhitepaper from './DownloadWhitepaper';
import FormHM from './FormHM';
import FormTalent from './FormTalent';
import Footer from './Footer';
import NavbarKnowHow from './NavbarKnowHow';

class KnowHow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            showPopup: false,
            message: '',
            dataProtectionConsent: false,
            showDownloadWhitepaper: false,
        };
    }

    handlePopupClose = () => {
        this.setState({ showPopup: false });
    };

    handleDownloadPopupClose = () => {
        this.setState({ showDownloadWhitepaper: false });
    }

    handleDownloadClick = () => {
        this.setState({ showDownloadWhitepaper: true });
    };


    handleSubscribe = async (e) => {
        e.preventDefault();
      
        try {
          if (!this.state.email) {
            this.setState({ message: 'Email is required' });
            return;
          }
      
          if (!this.state.dataProtectionConsent) {
            this.setState({
              message: 'Please consent to data protection',
            });
            return;
          }
      
          const response = await fetch('/api/subscribe', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: this.state.email }),
          });

          const data = await response.json();
      
          if (response.ok) {
            this.setState({
              message: 'Thank you for subscribing!',
              showPopup: true,
            });
          } else {
            const errorMessage = data.error || 'Failed to subscribe';
                this.setState({ message: errorMessage });
          }
        } catch (error) {
          console.error(error);
          this.setState({ message: 'Failed to subscribe' });
        }
      };

    render() {
        return (
            <div>
                <NavbarKnowHow />
                
                {/* HERO */}
                    <section id='intro'>
                        <div className='hero'>
                            <div className='hero-title'>
                                <h1>the industry <br/>
                                    know-how</h1>
                                        <h2 className='headline'>Unlock Your <span className='orange'>Career</span><br/> 
                                                <span className='orange'>Potential</span> and tune up Your <br/>
                                                <span className='orange'>Hiring Process:</span>  Exclusive <br/>
                                                Insights and Resources for <br/>
                                                <span className='orange'>Candidates</span> and <span className='orange'>Hiring Managers!</span>
                                        </h2>
                                        <button 
                                            type='button' 
                                            name='button'>
                                            <ScrollLink to='pdf'>EXPLORE</ScrollLink>
                                        </button>
                            </div>
                        </div>
                    </section>
                
                {/* MAIN SECTION */}
                
                <main id='main'>

                    {/* HIRING MANAGER */}
                        <section id='hiring-manager'>
                            <div className='sub-section'>
                                <div className='sub-item'>
                                    <h2>the hiring managers & hr</h2>
                                        <div className='sub-section'>
                                            <div className='sub-section-half'>
                                                <div className='sub-items'>
                                                    <h4>Streamline Recruitment Process</h4>
                                                    <p className='styled'>our resources provide you with proven strategies and best practices to streamline your recruitment process. from crafting compelling 
                                                        job descriptions and conducting effective interviews to implementing robust candidate assessment methods, we help you save time and effort while making 
                                                        informed hiring decisions.</p>
                                                </div>
                                                <div className='sub-items'>
                                                    <h4>Access Top Talent</h4>
                                                    <p className='styled'>gain a competitive edge in attracting and securing top talent. our insights help you understand the candidate market dynamics, enabling you to 
                                                        create attractive employer value propositions, build a strong employer brand, and effectively engage with high-quality candidates.</p>
                                                </div>
                                        </div>
                                        <div className='sub-section-half'>
                                                <div className='sub-items'>
                                                    <h4>Improve candidate selection</h4>
                                                    <p className='styled'>make confident and data-driven decisions when evaluating candidates. our advice equips you with advanced assessment techniques, interview guidance, 
                                                        and behavioral analysis frameworks to identify candidates who not only possess the required skills but also fit seamlessly within your company culture.</p>
                                                </div>
                                                <div className='sub-items'>
                                                    <h4>Stay informed and adaptable</h4>
                                                    <p className='styled'>our resources keep you updated on the latest recruitment trends, technology advancements, and industry insights. stay ahead of the curve and adapt your 
                                                    hiring strategies to changing market dynamics, ensuring your company remains competitive in attracting and retaining top talent.</p>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sub-item'>
                                    <h5>Don’t miss out on these exclusive benefits! Enter your email address below to access our 
                                        downloadable PDFs and unlock the hiring excellence your organization deserves.</h5>
                                    <FormHM />
                                </div>
                            </div>
                        </section>
                
                {/* TALENT */}
                <section id='talent'>
                        <div className='sub-section'>
                            <div className='sub-item'>
                                <h2>the talent</h2>
                                    
                                    <div className='sub-section'>
                                        <div className='black-box-left'>
                                            <div className='sub-section-half'>
                                                <div className='sub-items'>
                                                    <h4>Propel your career growth</h4>
                                                    <p className='styled'>our expert advice provides you with valuable insights, strategies, and resources to accelerate
                                                        your career growth. from personal branding and networking tips to interview techniques and negotiation strategies, 
                                                        we equip you with the tools you need to stand out and advance in your profession</p>
                                                </div>
                                                <div className='sub-items'>
                                                    <h4>Navigate the job market with confidence</h4>
                                                    <p className='styled'>stay ahead of the competition and confidently navigate the job market with our industry-specific
                                                        insights. we provide you with up-to-date trends, market analysis, and tailored job search strategies, empowering you 
                                                        to make informed decisions and find the right opportunities for your career.</p>
                                                </div>
                                            </div>
                                            <div className='sub-section-half'>
                                                <div className='sub-items'>
                                                    <h4>Access exclusive job opportunities</h4>
                                                    <p className='styled'>gain access to a wide range of exclusive job opportunities through our network of reputable 
                                                        companies. We partner with top organizations across industries, giving you an inside track to exciting roles that 
                                                        align with your skills, experience, and aspirations.</p>
                                                </div>
                                                <div className='sub-items'>
                                                    <h4>Enhance your professional development</h4>
                                                    <p className='styled'>expand your knowledge and skills through our curated resources. in our downloadable guides and 
                                                        industry reports, we offer you valuable resources that foster continuous learning and professional development.</p>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                            </div>
                            <div className='sub-item'>
                                <h5>Seize these exclusive benefits today! Enter your email address below to gain access to our downloadable PDFs and unlock the pathways 
                                    to career success that you truly deserve.</h5>
                                <FormTalent />
                            </div>
                        </div>
                    </section>

                {/* PDFS */}
                <h3>Access our <span className='orange'>exclusive</span><br/>
                    Downloadable PDFs</h3>

                        <section id='pdf'>
                            <div className='pdf-half'>
                                <h4>the hiring managers / hr</h4>
                                    <div className='pdf-columns'>
                                        <div className='pdf-column'>
                                            <div className='pdf-item'>
                                                <h5>Create Stunning Job Description Checklist</h5>
                                                <p className='styled'>discover the essential elements and best practices to craft compelling job descriptions that attract 
                                                    top talent and effectively communicate your hiring needs.</p>
                                            </div>
                                            <div className='pdf-item'>
                                                <h5>Recruitment Trends Whitepaper</h5>
                                                <p className='styled'>gain valuable industry insights into the evolving recruitment landscape, specifically focusing on 
                                                    software engineering positions. explore emerging trends and predictions to stay ahead in the competitive talent market.</p>
                                            </div>
                                        </div>
                                        <div className='pdf-column'>
                                            <div className='pdf-item'>
                                                <h5>Hiring Remote Workers Checklist</h5>
                                                <p className='styled'>navigate the unique challenges and considerations of hiring remote workers successfully. our checklist 
                                                    provides valuable insights to help you build a remote team that excels.</p>
                                            </div>
                                            <div className='pdf-item'>
                                                <h5>Tech Questions for Software Engineers</h5>
                                                <p className='styled'>dive into a comprehensive collection of technical questions designed to test and evaluate software 
                                                    engineering proficiency.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pdf-picture'>
                                        <img 
                                            src='images/hero.png'
                                            alt='a male hero cartoon in blue business siut being happy based on success or winning with glasses on his face' />
                                    </div>
                            </div>

                            <div className='pdf-half'>
                                <h4>the talent</h4>
                                        <div className='pdf-columns'>
                                            <div className='pdf-column'>
                                                <div className='pdf-item'>
                                                    <h5>Resume Preparation and Interview Guide</h5>
                                                    <p className='styled'>how to prepare for interviews: equip yourself with the essential strategies, tips, and techniques 
                                                        to confidently prepare for interviews. master the art of showcasing your skills and experiences effectively to 
                                                        impress potential employers.</p>
                                                </div>
                                            </div>
                                            <div className='pdf-column'>
                                                <div className='pdf-item'>
                                                    <h5>Tech Questions for Software Engineers</h5>
                                                    <p className='styled'>find value in this resource. dive into a comprehensive collection of technical questions designed 
                                                        to test and evaluate software engineering proficiency.</p>
                                                </div>
                                            </div>
                                        </div>      
                                        <div className='pdf-subscribe'>
                                            <h3 
                                                className='subscribe-h3'
                                                style={{ color: 'white' }}><span className='orange'>More Coming Soon:</span><br/>
                                                    for Priority Access!</h3>
                                            <p className='styled'>we’re thrilled to announce that our dedicated team is actively working on creating more valuable PDF resources for both hiring managers and candidates. 
                                                by subscribing to our newsletter, you’ll be among the first to receive the upcoming PDFs, ensuring you gain a competitive advantage in your hiring process or career journey. 
                                                stay informed about the latest industry insights, expert advice, and exclusive resources tailored to your needs. don’t miss out on these upcoming releases! simply enter 
                                                your email address below to subscribe and receive priority access to our future PDFs. stay ahead of the curve and unlock the next level of success in your recruitment 
                                                endeavors or professional growth.</p>
                                            <form onSubmit={this.handleSubscribe}>
                                                <label 
                                                    htmlFor='email'>
                                                        Your contact email address
                                                </label>
                                                    <input 
                                                        type='email'
                                                        id='email'
                                                        name='email'
                                                        autoComplete='email'
                                                        required
                                                        value={this.state.email}
                                                        onChange={(e) => this.setState({ email: e.target.value })}
                                                    /> 
                                                <div className='data-policy-subs'>
                                                    <input 
                                                        type='checkbox'
                                                        id='data-policy' 
                                                        name='data-policy'
                                                        required
                                                        checked={this.state.dataProtectionConsent}
                                                        onChange={() =>
                                                            this.setState({
                                                                dataProtectionConsent: !this.state
                                                                    .dataProtectionConsent,
                                                                    })
                                                                } />
                                                    <DataProtection />
                                                </div>
                                                <button type='submit'>SUBSCRIBE</button>
                                            </form>
                                            <p>{this.state.message}</p>
                                        </div>
                            </div> 
                        </section>

                {/* WHITEPAPER */}
                    <section id='whitepaper'>
                        <div className='whitepaper-columns'>
                            <div className='whitepaper-left'>
                                <h3>Download our <span className='orange'>Free<br/>
                                    White Paper</span>!</h3>
                                <h5>Discover the Future of IT Recruitment:<br/>
                                    Strategies for Success in a Digital Era</h5>
                                            <p>are you ready to unlock the secrets to revolutionizing your IT recruitment process? our exclusive white paper is your essential guide to conquering the evolving world 
                                                of IT talent acquisition.</p>
                                            <p>in this comprehensive report, we delve deep into the intricacies of IT recruitment, providing valuable insights, data, and expert strategies to ensure your recruitment 
                                                efforts shine in the digital age. </p>
 
                                <h2>Our <span className='orange'>White Paper</span> includes:</h2>
     
                                            <ul>
                                                <li>
                                                    <strong>Executive Summary:</strong> a condensed overview of the key points and takeaways.
                                                </li>
                                                <li>
                                                    <strong>Current Landscape of IT Recruitment:</strong> an in-depth analysis of the ever-changing IT job market.
                                                </li>
                                                <li>
                                                    <strong>Strategies for Successful IT Recruitment:</strong> tried-and-tested methods for attracting top-tier IT talent.
                                                </li>
                                                <li>
                                                    <strong>Leveraging Technology in IT Recruitment:</strong> harnessing technology to streamline your hiring process.
                                                </li>
                                                <li>
                                                    <strong>Nurturing and Retaining IT Talent:</strong> keeping your valuable IT professionals engaged and motivated.
                                                </li>
                                                <li>
                                                    <strong>Overcoming IT Recruitment Challenges:</strong> practical solutions to common hurdles in hiring.
                                                </li>
                                                <li>
                                                    <strong>Case Studies and Success Stories:</strong> real-life examples of effective IT recruitment strategies.
                                                </li>
                                                <li>
                                                    <strong>Future Trends in IT Recruitment:</strong> stay ahead of the curve with upcoming industry developments.
                                                </li>
                                                <li>
                                                    <strong>Conclusion:</strong> a final wrap-up and actionable steps to implement in your recruitment approach.
                                                </li>
                                            </ul>

                                            <p>don't miss this exclusive opportunity to gain a competitive edge in the IT recruitment landscape. simply enter your contact email address below to receive instant access 
                                                to our white paper. empower your team with the knowledge and strategies they need to thrive in the digital era of recruitment.</p>
                                            <p>download our free white paper now and revolutionize your IT recruitment journey! </p>
                                            
                                            <div>
                                                <button onClick={this.handleDownloadClick}>DOWNLOAD PDF</button>
                                                {this.state.showDownloadWhitepaper && (
           
                                                        <DownloadWhitepaper
                                                            onClose={this.handleDownloadPopupClose}
                                                        />
                                                    )}
                                            </div>

                            </div>
                        </div>
                        <div className='whitepaper-columns'>
                            <div className='whitepaper-picture'>
                                <img 
                                    src='images/document.png'
                                    alt='a document with secret content above which is a bulb giving a little light on the document dark blue bakcground' />
                            </div>              
                        </div>
                    </section>



                </main>
                <Footer />
            </div>
        );
    }
};



export default KnowHow;