// types.js
export const FETCH_USER = 'FETCH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const COLLECT_EMAIL = 'COLLECT_EMAIL';
export const COLLECT_CLIENT_EMAIL = 'COLLECT_CLIENT_EMAIL';
export const SUBSCRIBE = 'SUBSCRIBE';
export const WAITING_LIST = 'WAITING_LIST';
export const REFERRAL = 'REFFERAL';
export const CONTACT_FORM = 'CONTACT_FORM';
export const HIRING_MANAGER_SUBSCRIPTION = 'HIRING_MANAGER_SUBSCRIPTION';
export const TALENT_SUBSCRIPTION = 'TALENT_SUBSCRIPTION'
