import { 
  FETCH_USER, 
  LOGOUT_USER, 
  COLLECT_EMAIL, 
  COLLECT_CLIENT_EMAIL, 
  SUBSCRIBE, 
  WAITING_LIST, 
  REFERRAL, 
  CONTACT_FORM,
  HIRING_MANAGER_SUBSCRIPTION,
  TALENT_SUBSCRIPTION
} from '../actions/types';

const handleCommonData = (state, action) => {
  const { name, email, message } = action.payload;

  return {
    ...state,
    collectedName: name,
    collectedEmail: email,
    collectedMessage: message,
  };
};

const authReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
      case LOGOUT_USER:
        return false; // Set the authentication state to false when logging out
      case COLLECT_EMAIL:
      case COLLECT_CLIENT_EMAIL:
      case SUBSCRIBE:
      case WAITING_LIST:
          return {
          ...state,
          collectedEmail: action.payload.email, 
          emailCollectionSuccess: true, 
        };
        case REFERRAL:
          return {
          ...handleCommonData(state, action),
          collectedProfile: action.payload.profile,
          referralCollectionSuccess: true,
        };
        case CONTACT_FORM:
          return {
          ...handleCommonData(state, action),
          contactCollectionSuccess: true,
        };
        case HIRING_MANAGER_SUBSCRIPTION:
          return {
            ...handleCommonData(state, action),
            collectedJob: true,
            managerCollectionSuccess: true,
        };
        case TALENT_SUBSCRIPTION:
          return {
            ...state,
            collectedName: action.payload.name,
            collectedEmail: action.payload.email, 
            collectedJob: action.payload.job, 
            collectedCompany: action.payload.company, 
            collectedProfile: action.payload.profile, 
            talentCollectionSuccess: true,
          };
    default:
      return state;
  }
};

export default authReducer;