import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit'; 
import App from './components/App';
import reducers from './reducers';
import reduxThunk from 'redux-thunk';


const store = configureStore({
  reducer: reducers,
  middleware: [reduxThunk]
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
