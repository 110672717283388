import React, { Component } from 'react'; 

import DataProtection from './DataProtection';

class FormHM extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name:'',
            email: '',
            job: '',
            message: '',
            dataProtectionConsent: false,
            showFormHM: false,
            isContactPolicyChecked: true
        };
    }

    handleCheckboxChange = () => {
        this.setState({ isContactPolicyChecked: !this.state.isContactPolicyChecked });
    }

    handleHiringManagerSubscription = async (e) => {
        e.preventDefault();
    
        try {
            const { name, email, job, message, dataProtectionConsent } = this.state;
    
            if (!email) {
                this.setState({ message: 'Email is required' });
                return;
            }
    
            if (!dataProtectionConsent) {
                this.setState({
                    message: 'Please consent to data protection',
                });
                return;
            }
    
            const response = await fetch('/api/hiring-manager-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, job, message, dataProtectionConsent }),
            });
    
            const data = await response.json();
            console.log(data);
    
            if (response.ok) {
                this.setState({
                    message: 'Thank you for your subscription.',
                    showFormHM: false,
                });
            } else {
                const errorMessage = data.error || 'Failed to subscribe.';
                this.setState({ message: errorMessage });
            }
        } catch (error) {
            console.error(error);
            this.setState({ message: 'Failed to subscribe.' });
        }
    };


    render() {
        return (
            <div>
                <div className='right-form' onSubmit={this.handleHiringManagerSubscription}>
                <form id='contact-form'>
                    <label htmlFor='name'>
                        Your full name
                    </label>
                        <input 
                            type='text'
                            id='name' 
                            name='name'
                            autoComplete='name'
                            required
                            onChange={(e) => this.setState({ name: e.target.value })}
                            />
    
                    <label htmlFor='email'>
                        Your contact email address
                    </label>
                        <input 
                            type='email' 
                            id='email'
                            name='email' 
                            autoComplete='email'
                            required
                            onChange={(e) => this.setState({ email: e.target.value })}
                            />
                    
                    <label htmlFor='title'>
                        Your job title
                    </label>
                        <input 
                            type='text' 
                            id='job'
                            name='job' 
                            autoComplete='organization-title'
                            required
                            onChange={(e) => this.setState({ job: e.target.value })}
                            />

                    <label htmlFor='message'>
                        Your message
                    </label>
                        <textarea 
                            id='message' 
                            name='message' 
                            rows='4' 
                            onChange={(e) => this.setState({ message: e.target.value })}
                        ></textarea>
                    
                    <div className='contact-policy'>
                        <input 
                            type='checkbox' 
                            id='contact-policy' 
                            name='contact-policy' 
                            checked={this.state.isContactPolicyChecked}
                            onChange={this.handleCheckboxChange}
                            />
                                <label 
                                    htmlFor='contact-policy'>
                                        Yes, I want to be contacted by recberry <strong>to help me with the hiring process</strong>
                                </label>
                    </div>
                    
                    <div className='data-policy'>
                        <input 
                            type='checkbox' 
                            id='data-policy' 
                            name='data-policy' 
                            required
                            onChange={(e) => this.setState({ dataProtectionConsent: e.target.checked })}/>
                            <DataProtection />
                    </div>
                    <button type='submit'>SUBSCRIBE</button>
                </form>
                <p>{this.state.message}</p>
                </div>
            </div>
        );
    }
}



export default FormHM;